<template>
  <div class="container">
    <div class="card card-custom example example-compact">
      <div class="card-header">
        <h3 class="card-title">
          {{ $t("PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.TITLE") }}
        </h3>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center"></div>
        </div>
      </div>
      <form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <div class="row px-5">
            <div class="col-12 col-md-6">
              <h6>
                {{ $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_INFO") }}
              </h6>
              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >{{
                    $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_IDENTITY")
                  }}:</label
                >
                <span class="ml-2">{{
                  selectedOrder.orderNumber ? selectedOrder.orderNumber : null
                }}</span>
              </div>

              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >{{
                    $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_AMOUNT")
                  }}:</label
                >
                <span class="ml-2"
                  >{{ selectedOrder.price }} {{ selectedPaymentUnit }}</span
                >
              </div>

              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >{{
                    $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_AMOUNT")
                  }}:</label
                >
                <span class="ml-2"
                  >{{ selectedOrder.fee }}% ({{ feeAmount }}
                  {{ selectedPaymentUnit }})</span
                >
              </div>

              <div class="col-12">
                <label class="col-form-label font-weight-bold">
                  {{ $t("PAGES.SALES_MANAGEMENT.LINK_SALES.TOTAL_AMOUNT") }}:
                  <!-- Komisyon Fiyata Dahil Mi? -->
                </label>
                <!-- <span class="ml-2" v-if="selectedOrder.feeIncluded">Evet</span>
                <span class="ml-2" v-else>Hayır</span> -->
                <span class="ml-2"
                  >{{ totalAmount }} {{ selectedPaymentUnit }}</span
                >
              </div>

              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >{{
                    $t("PAGES.SALES_MANAGEMENT.LINK_SALES.TOTAL_QUANTITY")
                  }}:</label
                >
                <!-- <span class="ml-2">{{ totalAmount }}</span> -->
                <span class="ml-2"
                  >{{
                    selectedOrder.amount
                      ? selectedOrder.amount
                      : convertedCryptoAmount
                  }}
                  <template
                    v-if="
                      selectedCurrency &&
                        selectedCurrency.wallet &&
                        selectedCurrency.wallet.currencyCode
                    "
                  >
                    {{ selectedCurrency.wallet.currencyCode }}
                  </template>
                </span>
              </div>

              <div
                class="col-12"
                v-if="nonUserOrderWallet && nonUserOrderWallet.address"
              >
                <label class="col-form-label font-weight-bold"
                  >{{ $t("PAGES.SALES_MANAGEMENT.LINK_SALES.WALLET") }}:</label
                >
                <span class="ml-2">{{ nonUserOrderWallet.address }}</span>
              </div>

              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >{{
                    $t("PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_STATUS")
                  }}:</label
                >
                <span
                  class="ml-2 badge badge-warning"
                  v-if="selectedOrder.orderStatus === 'pending'"
                >
                  {{ $t("PAGES.SALES_MANAGEMENT.LIST.PENDING") }}
                </span>
                <span
                  class="ml-2 badge badge-info"
                  v-if="selectedOrder.orderStatus === 'proccessing'"
                >
                  {{ $t("PAGES.SALES_MANAGEMENT.LIST.PROCESSING") }}
                </span>
                <span
                  class="badge badge-success"
                  v-if="selectedOrder.orderStatus === 'completed'"
                >
                  {{ $t("PAGES.SALES_MANAGEMENT.LIST.COMPLETED") }}
                </span>
              </div>
            </div>

            <!-- <div class="col-12 col-md-6">
              <h6>Müşteri Bilgileri</h6>
              <div class="d-inline-flex col-12 align-items-center">
                <label class="col-form-label font-weight-bold">
                  {{ selectedOrder.customerName }}
                </label>
              </div>

              <div class="roe">
                <div class="d-inline-flex col-12 col-md-6 align-items-center">
                  <i class="menu-icon flaticon2-phone text-primary"></i>
                  <label class="col-form-label ml-2">
                    {{ selectedOrder.customerPhone }}
                  </label>
                </div>

                <div
                  v-if="selectedOrder.customerMail"
                  class="d-inline-flex col-12 col-md-6 align-items-center"
                >
                  <i class="menu-icon flaticon2-email text-primary"></i>
                  <label class="col-form-label ml-2">
                    {{ selectedOrder.customerMail }}
                  </label>
                </div>
              </div>
            </div> -->
          </div>
        </div>

        <div
          class="card-footer d-md-flex flex-md-wrap align-items-center justify-content-between"
          v-if="selectedOrder.orderStatus === 'pending'"
        >
          <currency-selector
            v-on:currency-selected="currencySelected"
            :currencies="orderCompanyWallet"
            :selectedCurrency="selectedCurrency"
          ></currency-selector>
          <div class="col-12 col-md-6 mt-md-0 mt-4">
            <input
              v-if="paymentUnitsLoading"
              type="text"
              class="form-control"
              placeholder="Loading..."
              disabled
            />
            <b-form-select
              v-else
              v-model="selectedPaymentUnit"
              :options="paymentUnits"
              text-field="label"
              value-field="name"
              @change="onChangePaymentUnit"
            >
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="null">{{
                  $t("FORM.SELECT_A_PAYMENT_UNIT")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <qr-code-reader
            :disabled="!selectedCurrency || !selectedPaymentUnit"
            v-on:qrcode-scanned="QRCodeScanned"
            :btn-title="
              $t(
                'PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.MIRACLE_CUSTOMER'
              )
            "
            container-class="col-12 col-md-6 mt-2"
          />
          <div class="col-12 col-md-6 mt-2">
            <b-button
              variant="outline-success"
              block
              :disabled="!selectedCurrency || !selectedPaymentUnit"
              @click="nonUserContinue"
            >
              <span>{{
                $t(
                  "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.NOT_MIRACLE_CUSTOMER"
                )
              }}</span>
            </b-button>
          </div>
        </div>
        <div
          class="card-footer d-md-flex flex-md-wrap align-items-center justify-content-between"
          v-else
        >
          <div class="col-12 col-md-4 mt-md-0 mt-4">
            <b-button variant="primary" block @click="gotoList">{{
              $t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.ALL_ORDERS"
              )
            }}</b-button>
          </div>
        </div>
      </form>
    </div>

    <!-- qrcode awaiting modal -->
    <b-modal
      v-model="isQrScanning"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <template #modal-title> </template>
      <b-alert show variant="info" class="pt-7">
        <div class="d-flex justify-content-center mb-3">
          <b-spinner label="Loading..." type="grow"></b-spinner>
        </div>
        <div class="d-flex justify-content-center mb-3">
          {{ $t("AUTH.LOGIN.LOADING_MESSAGE_TITLE") }}...
        </div>
      </b-alert>
    </b-modal>

    <b-modal
      v-model="showQrResult"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
    >
      <template #modal-title>
        <span v-if="qrSuccess">{{
          $t("PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_SUCCESS")
        }}</span>
        <span v-else>{{
          $t("PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_ERROR")
        }}</span>
      </template>
      <b-alert show :variant="variant">
        <h3 class="m-0 py-3">
          <strong
            class="
              d-flex
              flex-1 flex-column
              align-items-center
              justify-content-center
            "
            v-if="qrSuccess"
          >
            <span>{{
              $t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.SUCCESS_TITLE"
              )
            }}</span>
            <span class="mt-3">{{
              $t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.SUCCESS_SUBTITLE"
              )
            }}</span>
          </strong>
          <strong
            class="
              d-flex
              flex-1 flex-column
              align-items-center
              justify-content-center
            "
            v-else
          >
            <span>{{
              $t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.AN_ERROR_HAS_OCCURRED"
              )
            }}</span>
            <span class="mt-2">{{
              $t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.ERROR_SUBTITLE"
              )
            }}</span>
            <small class="d-block mt-4 font-size-small">{{
              qrErrorMessage
            }}</small>
          </strong>
        </h3>
      </b-alert>
      <template #modal-footer>
        <div class="d-flex flex-1">
          <div v-if="!qrSuccess" class="d-inline-flex">
            <b-button
              class="d-inline-flex justify-self-start"
              variant="warning"
              @click="tryAgain"
              >{{
                $t(
                  "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.TRY_AGAIN"
                )
              }}</b-button
            >
          </div>
          <div class="d-inline-flex flex-1 justify-content-end">
            <b-button class="mr-3" variant="primary" @click="gotoList">{{
              $t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.ALL_ORDERS"
              )
            }}</b-button>
            <b-button variant="primary" @click="newSale">{{
              $t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.NEW_ORDER"
              )
            }}</b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="nonUser"
      v-model="nonUserModalShow"
      centered
      hide-footer
      modal-class="body-class text-center"
      @show="onNonUserModalShow"
      @hide="onNonUserModalHide"
      scrollable
    >
      <template #modal-header="{ close }">
        <h2 class="m-0 w-100 text-primary w-100 text-center font-weight-bolder">
          &nbsp;
        </h2>
        <button
          type="button"
          aria-label="Close"
          @click="onPressCloseNonUserModal"
          class="close"
        >
          ×
        </button>
      </template>

      <template v-if="nonUserOrderWalletLoading">
        <!-- <b-icon
            icon="gear"
            font-scale="8"
            animation="spin"
            class="mb-4"
        ></b-icon> -->
        <b-icon
          icon="three-dots"
          font-scale="8"
          animation="cylon"
          class="mb-4"
        ></b-icon>
      </template>

      <template v-else>
        <template v-if="nonUserOrderWallet.address">
          <div class="non-mcm-user-qrcode-wrapper">
            <qrcode-vue
              :value="nonUserOrderWallet.address"
              :size="250"
              level="H"
            />
          </div>
          <!-- <img
            style="width: 100%; max-width: 250px; height: auto;"
            :src="
              'https://chart.googleapis.com/chart?chs=400x400&cht=qr&chld=L&chl=' +
                nonUserOrderWallet.address
            "
            alt=""
          /> -->
          <h3
            v-if="
              selectedCurrency &&
                selectedCurrency.wallet &&
                selectedCurrency.wallet.currencyCode
            "
          >
            {{ selectedCurrency.wallet.currencyCode }}
          </h3>
          <h4 class="font-weight-light" v-if="walletNetworkName">
            {{ $t("COMMON.network") }}: <strong>{{ walletNetworkName }}</strong>
          </h4>
          <h3 v-if="selectedOrder.amount">{{ selectedOrder.amount }}</h3>
          <h6 v-if="nonUserOrderWallet.address">
            {{ nonUserOrderWallet.address }}
          </h6>
          <p class="text-danger">
            <strong>{{
              $t("PAGES.SALES_MANAGEMENT.QR_SALE.nexusCreatePaymentWarning")
            }}</strong>
          </p>
          <b-button
            variant="danger"
            size="sm"
            @click="onDeleteNonUserWalletAddress"
            v-if="selectedOrder.orderStatus === 'pending'"
            style="margin-bottom: 40px;"
          >
            {{ $t("PAGES.SALES_MANAGEMENT.QR_SALE.cancel_transaction") }}
          </b-button>
        </template>
        <div
          class="d-flex flex-column h-100 justify-content-center align-items-center"
          v-else
        >
          <b-alert class="w-75" show>
            <h5 class="alert-heading">
              {{
                $t(
                  "PAGES.SALES_MANAGEMENT.QR_SALE.active_wallet_address_not_found_please_try_again_later"
                )
              }}
            </h5>
          </b-alert>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import CurrencySelector from "./../../../components/currency/CurrencySelector.vue";
import QrCodeReader from "../../../components/QrCode/QrCodeReader.vue";
import Swal from "sweetalert2";
import QrcodeVue from "qrcode.vue";

export default {
  components: { CurrencySelector, QrCodeReader, QrcodeVue },
  data() {
    return {
      orderCompanyWallet: [],
      selectedCurrency: null,
      startScan: false,
      qrCode: null,
      showQrResult: false,
      variant: null,
      qrSuccess: false,
      qrErrorMessage: null,
      nonUserModalShow: false,
      isQrScanning: false,
      selectedPaymentUnit: null,
      checkOrderStatusInterval: null,
      convertedCryptoAmount: "",
      nonUserOrderWalletLoading: false
    };
  },
  mounted() {
    // delete wallet address on page refresh
    if (this.nonUserOrderWallet?.address) {
      this.performDeleteWalletAddress();
      console.log("perform delete wallet address non mcm user");
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.SALES_MANAGEMENT") },
      { title: this.$t("ROUTES.QR_SALE") }
    ]);
    this.$store.dispatch("paymentunits/GET_PAYMENT_UNITS");
  },
  created() {
    this.getWallets(true)
      .then(wallets => {
        this.orderCompanyWallet = wallets;
      })
      .catch(() => {});
  },
  destroyed() {
    if (this.checkOrderStatusInterval) {
      clearInterval(this.checkOrderStatusInterval);
    }
    this.CLEAR_SELECTED_ORDER();
  },
  computed: {
    ...mapState({
      selectedOrder: state => state.orders.selectedOrder,
      nonUserOrderWallet: state => state.orders.nonUserOrderWallet,
      paymentUnitsLoading: state => state.paymentunits.loading,
      paymentUnits: state => state.paymentunits.data,
      walletNetworkName: state =>
        state.orders.nonUserOrderWallet?.walletNetwork?.name || ""
    }),
    feeAmount() {
      let value = this.selectedOrder?.feeAmount;
      if (!value) {
        const price = this.selectedOrder?.price
          ? parseFloat(this.selectedOrder.price)
          : 0;
        const fee = this.selectedOrder?.fee
          ? parseFloat(this.selectedOrder.fee)
          : 0;
        value = (price * fee) / 100;
      }
      return value;
    },
    totalAmount() {
      let value = this.selectedOrder?.orderTotal;
      if (!value) {
        const price = this.selectedOrder?.price
          ? parseFloat(this.selectedOrder.price)
          : 0;
        const isFeeIncludedPrice = this.selectedOrder.feeIncluded;

        value = isFeeIncludedPrice ? price + this.feeAmount : price;
      }
      return value;
    }
  },
  methods: {
    ...mapActions({
      UPDATE_SELECTED_QR_CURRENCY: 'orders/"UPDATE_SELECTED_QR_CURRENCY',
      CREATE_QR_SALE: "orders/CREATE_QR_SALE",
      GET_NON_USER_WALLET: "orders/GET_NON_USER_WALLET",
      CLEAR_NON_USER_WALLET: "orders/CLEAR_NON_USER_WALLET",
      getWallets: "company/GET_COMPANY_WALLETS",
      CHECK_ORDER_STATUS: "orders/CHECK_ORDER_STATUS",
      CLEAR_SELECTED_ORDER: "orders/CLEAR_SELECTED_ORDER"
    }),
    currencySelected(currency) {
      this.selectedCurrency = currency;
      this.selectedOrder["selectedCurrencyId"] = currency.id;
      this.selectedOrder["selectedCurrencyCode"] = currency.wallet.currencyCode;
      if (!!currency?.wallet?.currencyCode && this.selectedPaymentUnit) {
        this.convertCurrencyToCrypto({
          crypto: currency.wallet.currencyCode,
          currency: this.selectedPaymentUnit
        });
      }
    },
    tryAgain() {
      this.showQrResult = false;
    },
    QRCodeScanned(qrCode) {
      // valid: e2130b297f605aedb30020158c935830b34a23028b8de065ec5b0b4987faf6fb

      if (qrCode) {
        this.isQrScanning = true;
        this.$store
          .dispatch("orders/CREATE_QR_SALE", {
            orderId: this.selectedOrder?.id
              ? this.selectedOrder.id
              : this.selectedOrder._id,
            selectedCurrencyId: this.selectedOrder.selectedCurrencyId,
            selectedCurrencyCode: this.selectedOrder.selectedCurrencyCode,
            hash: qrCode,
            selectedPaymentName: this.selectedPaymentUnit
          })
          .then(res => {
            this.qrSuccess = res.status === 200;

            this.variant = this.qrSuccess ? "success" : "danger";
            this.qrErrorMessage = this.qrSuccess ? null : res?.data.message;

            this.qrCode = qrCode;
            this.showQrResult = true;
            this.isQrScanning = false;
          })
          .catch(err => {
            this.qrSuccess = false;
            this.variant = "danger";
            this.qrErrorMessage =
              err?.response?.data?.message || this.$t("FORM.ERROR");
            this.qrCode = qrCode;
            this.showQrResult = true;
            this.isQrScanning = false;
          });
      }
    },
    gotoList() {
      this.$router.replace({ name: "sales-list" });
    },
    newSale() {
      this.$router.replace({ name: "link-sales" });
    },
    nonUserContinue() {
      if (!this.selectedPaymentUnit) {
        return;
      }
      this.nonUserModalShow = true;
      const params = {
        currency: this.selectedCurrency.wallet.currencyCode,
        orderId: this.selectedOrder?.id
          ? this.selectedOrder.id
          : this.selectedOrder._id,
        payment: this.selectedPaymentUnit
      };
      this.nonUserOrderWalletLoading = true;
      this.GET_NON_USER_WALLET(params)
        .then(() => {
          this.nonUserOrderWalletLoading = false;
        })
        .catch(() => {
          this.nonUserOrderWalletLoading = false;
        });
      // console.log("nonUserContinue");
    },
    onNonUserModalShow() {
      const self = this;
      this.checkOrderStatusInterval = setInterval(function() {
        const orderId = self.selectedOrder?.id
          ? self.selectedOrder.id
          : self.selectedOrder._id;
        self
          .CHECK_ORDER_STATUS(orderId)
          .then(res => {
            const {
              orderStatus,
              nexusAmount,
              nexusAmountConvertCurrency,
              amount
            } = res.data;
            self.selectedOrder["orderStatus"] = orderStatus;
            self.selectedOrder["amount"] = amount;

            const isCompleted = orderStatus === "completed";
            const isNotEnough = orderStatus === "notEnough";
            const isConfirming = orderStatus === "confirming";
            const shouldShowSwallAlert =
              isCompleted || isConfirming || isNotEnough;
            const shouldClearInterval = isCompleted || isNotEnough;
            let swallIcons = {
              completed: "success",
              notEnough: "warning",
              confirming: "warning"
            };
            let swallTitles = {
              completed: self.$t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.ORDER_COMPLETED"
              ),
              notEnough: self.$t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.MISSING_ORDER_PAYMENT"
              ),
              confirming: self.$t(
                "PAGES.SALES_MANAGEMENT.LIST.ORDER_STATUSES.8"
              )
            };
            const bodyHtml = `<div class="w-100">
              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >${self.$t(
                    "PAGES.SALES_MANAGEMENT.QR_SALE.ORDER_AMOUNT_IS"
                  )}:</label
                >
                <span class="ml-2">${nexusAmountConvertCurrency ||
                  "0"} ${self.selectedPaymentUnit?.toUpperCase()}</span>
              </div>
              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >${self.$t(
                    "PAGES.SALES_MANAGEMENT.QR_SALE.ORDER_CRYPTO_AMOUNT_IS"
                  )}:</label
                >
                <span class="ml-2">${nexusAmount ||
                  "0"} ${self.selectedCurrency?.wallet?.currencyCode?.toUpperCase()}</span>
              </div>
            </div>`;
            let swallHtml = isCompleted || isNotEnough ? bodyHtml : "";

            if (shouldShowSwallAlert) {
              shouldClearInterval &&
                clearInterval(self.checkOrderStatusInterval);
              Swal.fire({
                icon: swallIcons[orderStatus],
                title: swallTitles[orderStatus],
                html: swallHtml,
                confirmButtonText: self.$t("FORM.OK"),
                allowOutsideClick: false
              }).then(res => {
                if (res.isConfirmed) {
                  self.newSale();
                }
              });
            }
            if (!self.nonUserOrderWallet?.address) {
              clearInterval(self.checkOrderStatusInterval);
            }
          })
          .catch(() => {});
      }, 5000);
    },
    onNonUserModalHide() {
      // console.log("onNonUserModalHide: ", this.selectedOrder);
      if (this.checkOrderStatusInterval) {
        clearInterval(this.checkOrderStatusInterval);
      }
    },
    onChangePaymentUnit() {
      if (this.selectedPaymentUnit && !!this.selectedCurrency) {
        this.convertCurrencyToCrypto({
          crypto: this.selectedCurrency.wallet.currencyCode,
          currency: this.selectedPaymentUnit
        });
      }
    },
    async convertCurrencyToCrypto({ crypto, currency }) {
      try {
        const res = await this.$store.dispatch("calculator/MPAY_CONVERT", {
          crypto,
          currency,
          amount: this.totalAmount
        });
        this.convertedCryptoAmount = res.data?.crypto_amount || "";
      } catch (error) {
        console.log(error.response?.data?.message || "");
      }
    },
    onDeleteNonUserWalletAddress() {
      const vm = this;
      Swal.fire({
        title: this.$t(
          "PAGES.SALES_MANAGEMENT.QR_SALE.cancel_transaction_warning"
        ),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("FORM.CONFIRM"),
        cancelButtonText: this.$t("FORM.CANCEL")
      }).then(result => {
        if (result.value) {
          vm.performDeleteWalletAddress();
        }
      });
    },
    async performDeleteWalletAddress() {
      this.$store.commit("config/TOGGLE_OVERLAY", true);
      try {
        await this.$store.dispatch(
          "orders/DELETE_WALLET_ADDRESS",
          this.nonUserOrderWallet.address
        );
        this.$store.commit("config/TOGGLE_OVERLAY", false);
        this.nonUserModalShow = false;
        this.$root.$bvToast.toast(this.$t("COMMON.operation_performed"), {
          title: this.$t("FORM.SUCCESS_TITLE"),
          variant: "success",
          autoHideDelay: 5000
        });
        this.CLEAR_NON_USER_WALLET();
        this.newSale();
      } catch (error) {
        console.log("QrSale@performDeleteWalletAddress ", error);
        this.$store.commit("config/TOGGLE_OVERLAY", false);
        this.$root.$bvToast.toast(this.$t("FORM.ERROR"), {
          title: this.$t("COMMON.INFORM"),
          variant: "danger",
          autoHideDelay: 5000
        });
      }
    },
    onPressCloseNonUserModal() {
      if (this.nonUserOrderWallet?.address) {
        this.onDeleteNonUserWalletAddress();
        return;
      }
      this.nonUserModalShow = false;
      this.newSale();
    }
  }
};
</script>

<style lang="scss" scoped>
.font-size-small {
  font-size: small;
}

.non-mcm-user-qrcode-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}
</style>
